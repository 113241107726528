import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import request from '../../services/request.js';
import local from '../../services/local.js';
import helper from '../../services/helper';
import Loader from '../../controls/Loader';
import AccountKit from 'react-facebook-account-kit';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: true,
      tokenCapcha: 0,
      captchaText: '',
      captcha: null,
      accountKitToken: ''
    };
  }
  componentDidMount() {
    this.loadCaptcha();
  }
  async loadCaptcha() {
    //load captcha
    let captInfo = await request.request('/api/user/create-captcha');
    this.setState({
      loading: false,
      tokenCapcha: captInfo.tokenCapcha,
      captcha: captInfo.data
    });
  }
  async onLoginClick(e) {
    e.preventDefault();
    try {
      let rs = await request.request('/api/user/login', {
        username: this.state.username,
        password: this.state.password,
        tokenCapcha: this.state.tokenCapcha,
        captchaText: this.state.captchaText,
        accountKitToken: this.state.accountKitToken
      });
      if (rs.code) {
        return helper.alert(rs.message);
      }
      local.set('session', rs.data.token);
      local.set('user', JSON.stringify(rs.data.userInfo));
      helper.setUserInfo(rs.data.userInfo)
      this.props.history.push(`/`);
    } catch (err) {
      this.loadCaptcha();
      helper.alert(err.message);
    }
    // return false;
  }
  async handleAccountKitResponse(result) {
    console.log('on token', result);
    this.setState({ accountKitToken: result.code });
  }
  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <h2>Đăng nhập</h2>
                        <p className="text-muted">
                          Đăng nhập hệ thống quản trị
                        </p>
                      </Col>
                      <Col md={6}>
                        <img
                          src="assets/img/logo.png"
                          className="login-logo"
                          alt="avatar"
                        />
                      </Col>
                    </Row>

                    <form
                      onSubmit={this.onLoginClick.bind(this)}
                      autoComplete="off"
                    >
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Tên đăng nhập"
                          value={this.state.username}
                          onChange={evt =>
                            this.setState({ username: evt.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Mật khẩu"
                          value={this.state.password}
                          autoComplete="off"
                          onChange={evt =>
                            this.setState({ password: evt.target.value })
                          }
                        />
                      </InputGroup>
                      {/* <InputGroup className="mb-4">
                        <AccountKit
                          appId="807230106321433" // Update this!
                          version="v1.0" // Version must be in form v{major}.{minor}
                          onResponse={resp =>
                            this.handleAccountKitResponse(resp)
                          }
                          csrf={'csrf token here!'} // Required for security
                          countryCode={'+84'} // eg. +60
                          phoneNumber={''} // eg. 12345678
                          emailAddress={''} // eg. me@site.com
                        >
                          {p => {
                            return this.state.accountKitToken ? (
                              <Button
                                variant="contained"
                                {...p}
                                size="medium"
                                color="success"
                                block
                              >
                                Đã xác thực. Thay đổi?
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                outline
                                {...p}
                                size="medium"
                                color="primary"
                                block
                              >
                                Xác thực số điện thoại
                              </Button>
                            );
                          }}
                        </AccountKit>
                      </InputGroup> */}

                      <Row className="mb-4">
                        <Col md={5}>
                          <div
                            className="captcha"
                            dangerouslySetInnerHTML={{
                              __html: this.state.captcha
                            }}
                          ></div>
                        </Col>
                        <Col md={7}>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <Input
                                style={{
                                  height: 50,
                                  boxShadow: 'none',
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0
                                }}
                                type="text"
                                placeholder="Nhập mã captcha"
                                value={this.state.captchaText}
                                onChange={evt =>
                                  this.setState({
                                    captchaText: evt.target.value
                                  })
                                }
                              />
                              <InputGroupAddon addonType="prepend">
                                <Button
                                  style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    boxShadow: 'none'
                                  }}
                                  color="light"
                                  type="button"
                                  onClick={() => {
                                    this.loadCaptcha();
                                  }}
                                >
                                  <i className="fa fa-refresh" />
                                </Button>
                              </InputGroupAddon>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Button className="btn-login" type="submit" block>
                            Đăng nhập
                          </Button>
                        </Col>
                        {/* <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" type='button' block>Quên mật khẩu?</Button>
                        </Col> */}
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
