import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  List,
  Tooltip,
  Modal,
  Divider,
  message,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
  DashOutlined,
} from "@ant-design/icons";
import _, { clone, omit, concat } from "lodash";
// import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import ProductDetail from './ProductDetail';
import useDeepCompareEffect from '../useDeepCompareEffect';
import request from '../../services/request'
import helper from '../../services/helper'
import AttributeSelect from './AttributeSelect';
import "./ProductAttribute.css";



export const insertArrByIndex = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index)
];
export const getId = () => moment(new Date()).format("YYYYMMDDhhmmssSSSS");
export const MAX_ATTRIBUTE = 2;
const MAX_ATTRIBUTE_VALUES = 10;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 6 },
  },
};
const formItemWidth = "70%";
const CardInfo = ({ attrValue }) => {
  // console.log('CardInfo -> attrValue', attrValue);
  const cpAttrValue = omit(attrValue, ["isNew", "id", "product", "key", "attrIds"]);
  // const cpAttrValue = omit(attrValue, ["isNew", "id", "product", "key", "attrIds", "isEmbeedDataProducttype"]);
  return (
    <div className="cardInfo">
      {Object.keys(cpAttrValue).map((attr) => {
        return (
          <>
            <div>
              <p></p>
              <p>{attr}</p>
            </div>
            <div>
              <p></p>
              <p>{cpAttrValue[attr]}</p>
            </div>
          </>
        );
      })}
    </div>
  );
};

const Product = ({ pAttrs, pAttrValues, dataChange, resetDataChange, onChange }) => {
  const [modalVisibile, setModalVisibile] = useState(false);
  const [data, setData] = useState(pAttrValues);
  const [dataDetail, setDataDetail] = useState();
  const finalData = (datafinal) => {
    if (onChange) {
      onChange({
        attrs: pAttrs,
        productDetail: datafinal ? datafinal : data
      });
    }
  };

  const toggle = (detail) => {
    if (detail) {
      setDataDetail(detail);
    } else {
      setDataDetail(null);
    }
    setModalVisibile(!modalVisibile);
  };

  /* useEffect(() => {

    console.log('Product -> pAttrs', pAttrs);
    if (onChange) {
      onChange({
        ...data,
        attrs: pAttrs,
      });
    }
  }, [pAttrs]) */

  useEffect(() => {
    console.log('Product -> pAttrValues', pAttrValues);
    console.log('Product -> data', data);
    // if (!_.isEqual(data, pAttrValues)) {
    console.log('Product -> dataChange', dataChange);
    let cpFinalData = _.cloneDeep(pAttrValues);
    if (dataChange.length > 0 && dataChange[0].type === 'ADD') {
      cpFinalData = [];
      /* cpFinalData = _.cloneDeep(pAttrValues);
      pAttrValues.forEach((item, idx) => {
        let cpItem = _.cloneDeep(data[idx]);
        cpItem = _.pick(cpItem, ['product']);
        if (cpItem) {
          cpFinalData[idx] = {
            ...item,
            ...cpItem,
          };
        }
      }); */
      cpFinalData = _.cloneDeep(data);
      dataChange.forEach((dChange, dix) => {
        if (dChange.type === 'ADD') {
          const appendItem = pAttrValues
            .find(i => i.id === `${dChange.attrValIndex}`);

          // if (data.map(i => i.id).includes(dChange.attrValIndex.split('-')[0])) {
          let idx = dChange.attrValIndex.split('-')[0];
          let idy = dChange.attrValIndex.split('-')[1];
          const appendId = `${idx}-${idy}`;
          // const appendId = `${dChange.attrValIndex.split('-')[0]}-${dChange.attrValIndex.split('-')[1] - 1}`;
          // console.log('Product -> appendId', appendId);
          const appendIndex = pAttrValues.findIndex(i => i.id === appendId) - 1;

          /* if (dix === 0) {
            cpFinalData = insertArrByIndex(cpFinalData, appendIndex, appendItem || {});
          } else {
            cpFinalData = insertArrByIndex(cpFinalData, appendIndex + 1, appendItem || {});
          } */
          cpFinalData = insertArrByIndex(cpFinalData, appendIndex + 1, appendItem || {});
          // console.log('Product -> cpFinalData', cpFinalData);
        };
      });
    } else if (dataChange.length > 0 && dataChange[0].type === 'DEL') {
      cpFinalData = [];
      cpFinalData = _.cloneDeep(data);
      for (let dChange of dataChange) {
        if (dChange.type === 'DEL') {
          const delId = `${dChange.attrValIndex}`;
          cpFinalData = cpFinalData.filter(i => i.id !== delId);
        }
      }

      pAttrValues.forEach((item, idx) => {
        console.log('Product -> item', item);
        let cpItem = _.cloneDeep(cpFinalData[idx]);
        console.log('Product -> cpItem', cpItem);
        cpItem = _.pick(cpItem, ['product']);
        if (cpItem) {
          cpFinalData[idx] = {
            ...item,
            ...cpItem,
          };
        }
      });
    } else if (data.length === pAttrValues.length) {
      cpFinalData = [];
      cpFinalData = _.cloneDeep(pAttrValues);
      cpFinalData.forEach((item, idx) => {
        const assignAttrVal = data[idx];
        const prodDetail = _.pick(assignAttrVal, ['product']);
        item.product = prodDetail.product || {};
      });
    }

    console.log('Product -> cpFinalData', cpFinalData);
    setData(cpFinalData);
    finalData(cpFinalData);
    resetDataChange([]);
    // }
  }, [pAttrValues]);

  const onProductChange = (dataDetail) => {
    let isExist = false;
    data.forEach(item => {
      if (item.id === dataDetail.id) {
        if (dataDetail.product && !dataDetail.product.id) {
          item.product = {
            ...dataDetail.product,
            isNew: true
          };
        }
        else item.product = dataDetail.product;
        isExist = true;
      }
    });
    if (!isExist) {
      data.push(dataDetail);
    }

    finalData(data);
  };

  return (
    <List
      rowKey="id"
      header={<b>Danh sách sản phẩm</b>}
      bordered={false}
      className="productList"
      style={{ marginTop: "20px" }}
      grid={{ gutter: 12, xxl: 3, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }}
      dataSource={data}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <Card
            title={`Sản phẩm ${index + 1}`}
            bordered={true}
            actions={[
              <Tooltip key="download">
                <DashOutlined />
              </Tooltip>,
              <Tooltip title="Sửa sản phẩm" key="edit">
                <EditOutlined
                  onClick={() => {
                    console.log('Product onClick-> data', data);
                    console.log('Product onClick-> item', item);

                    toggle(item);
                  }}
                />
              </Tooltip>,
            ]}
            style={{ backgroundColor: "#fafafa" }}
          >
            <CardInfo attrValue={item} />
            <ProductDetail
              product={dataDetail}
              modalVisibile={modalVisibile}
              onChange={onProductChange}
              toggle={toggle}
              // dataSchema={dataSchema}
            />
          </Card>
        </List.Item>
      )}
    />
  );
};

const ProductAttributeValue = ({ formId, onFieldsChange, pAttrValue, removeProduct, addProduct, /*allValues,*/ setError, checkedValueAll, listSelectAttrs }) => {
  // console.log('ProductAttributeValue -> pAttrValue', pAttrValue);
  const [form] = Form.useForm();
  const [validateStatus, setValidateStatus] = useState({});
  const [help, setHelp] = useState({});
  console.log("@@form", form)

  const checkedValue = (e, key) => {
    /* const curField = form.current.getFieldInstance(['attrValues', key]);
    console.log('checkedValue -> curField', curField); */
    let { attrValues } = form.current.getFieldsValue();
    attrValues = attrValues.filter((i, index) => index !== key);
    if (e.target.value === "" || e.target.value === null || !e.target.value) {
      setValidateStatus(_.assign(validateStatus, { [key]: "error" }));
      setHelp(
        _.assign(help, { [key]: "Phân loại sản phẩm, ví dụ đỏ, trắng." })
      );
      setError(true);
    } else if (attrValues.includes(e.target.value)) {
      setValidateStatus(_.assign(validateStatus, { [key]: "error" }));
      setHelp(
        _.assign(help, { [key]: "Các phân loại sản phẩm phải khác nhau." })
      );
      setError(true);
    } else {
      setValidateStatus(_.assign(validateStatus, { [key]: null }));
      setHelp(_.assign(help, { [key]: null }));
      setError(false);
    }
  };

  /* const checkedValueAll = (value) => {
    if (!value || value === '') {
      setError(true);
      return false;
    }
    let isnotExisted = true;
    const curValues = allValues[formId];
    const keys = Object.keys(allValues).filter(i => i.toString() !== formId.toString());
    let arrValues = [];
    keys.forEach(key => {
      arrValues = _.concat(arrValues, allValues[key]);
    });

    if (curValues && curValues.attrValues) {
      if (curValues.attrValues) {
        const exArrVals = curValues.attrValues.filter(val => val === value);
        if (exArrVals.length > 0) {
          isnotExisted = false;
        }
      }
    }
    for (let i = 0; i < arrValues.length; i++) {
      let objVal = arrValues[i];
      if (objVal.attr === value) {
        isnotExisted = false;
        break;
      }
      if (objVal.attrValues) {
        const exArrVals = objVal.attrValues.filter(val => val === value);
        if (exArrVals.length > 0) {
          isnotExisted = false;
          break;
        }
      }
    };

    setError(!isnotExisted);
    return isnotExisted;
  }; */

  return (
    <Form
      ref={form}
      // form={form}
      name={`dynamic_form_attr_${formId}`}
      {...formItemLayoutWithOutLabel}
      // onFinish={onFinish}
      initialValues={{
        attr: { key: pAttrValue.attrId, value: pAttrValue.name },
        attrValues:
          pAttrValue.attributeValues && pAttrValue.attributeValues.length > 0
            ? pAttrValue.attributeValues.map((i) => i.name)
            : [""],
      }}
      onFieldsChange={(changedFields, allFields) => {
        onFieldsChange(formId, changedFields, allFields);
      }}
    >
      <Form.Item
        {...formItemLayout}
        validateTrigger={["onChange", "onBlur"]}
        rules={[
          {
            required: true,
            // whitespace: true,
            message: "Nhập tên nhóm phân loại, ví dụ màu sắc.",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!checkedValueAll(value, formId)) {
                return Promise.reject('Các phân loại phải khác nhau!');
              }
              return Promise.resolve();
            },
          }),
        ]}
        label={"Tên nhóm phân loại"}
        name={`attr`}
      >
        {/* <Input
          placeholder="Nhập tên nhóm phân loại, ví dụ màu sắc."
          style={{ width: formItemWidth }}
        /> */}
        <AttributeSelect
          style={{ width: formItemWidth }}
          placeholder={"Nhập tên nhóm phân loại"}
          mode="simple"
          data={listSelectAttrs.map(re => ({ key: re.id, value: re.name }))}
          onChange={(values) => {
            // setResource(values.key);
            console.log('ProductAttributeValue -> values', values);
          }}
        />
      </Form.Item>
      <Form.List name="attrValues">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Phân loại hàng" : ""}
                    required={true}
                    key={field.name}
                    validateStatus={validateStatus[field.name]}
                    help={help[field.name]}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Phân loại sản phẩm, ví dụ đỏ, trắng.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Phân loại sản phẩm, ví dụ đỏ, trắng."
                        style={{ width: formItemWidth }}
                        onChange={(e) => checkedValue(e, field.name)}
                        onBlur={(e) => checkedValue(e, field.name)}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: "0 8px" }}
                        onClick={() => {
                          removeProduct(formId, field.name, form.current.getFieldValue('attrValues')[field.name]);
                          remove(field.name);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                );
              })}
              {fields.length <= MAX_ATTRIBUTE_VALUES && (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      addProduct(formId, fields.length);
                      add();
                    }}
                    style={{ width: formItemWidth }}
                  >
                    <PlusOutlined /> Đã thêm ({fields.length}/10)
                  </Button>
                </Form.Item>
              )}
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
};

const ProductAttribute = ({
  attributes = [],
  attributesValues = [],
  itemId = null,
  onChange,
  setError,
  listSelectAttrs = [],
  dataSchema,
  pageId
}) => {
  // console.log('ProductAttribute => attributes', attributes);
  // console.log('ProductAttribute => attributesValues', attributesValues);
  const [attrs, setAttrs] = useState(attributes);
  const [pAttrs, setPAttrs] = useState(attributes);
  const [listSelectAttrsState, setListSelectAttrsState] = useState(listSelectAttrs)
  const [pAttrValues, setPAttrValues] = useState(attributesValues);
  const [dataFormAttr, setDataFormAttr] = useState({});
  const [dataChange, setDataChange] = useState([]);
  const [isShowModalAttribute, setIsShowModalAttribute] = useState(false);
  const [nameAttribute, setNameAttribute] = useState("");


  useEffect(() => {
    setListSelectAttrsState(listSelectAttrs)
  }, [listSelectAttrs.length])
  useDeepCompareEffect(() => {
    setPAttrValues(attributesValues);
  }, [attributesValues]);

  useDeepCompareEffect(() => {
    let cpDataFormAttr = clone(dataFormAttr);
    cpDataFormAttr = attributes.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.formId]: {
          attr: cur,
          attrValues:
            cur.attributeValues && cur.attributeValues.length > 0
              ? cur.attributeValues.map((i) => i.name)
              : [],
          isNew: false
        },
      };
    }, {});
    setPAttrs(attributes);
    setDataFormAttr(cpDataFormAttr);
  }, [attributes]);

  const addNameAttribute = async () => {

    // const urlParams = new URLSearchParams(queryString);
    if (nameAttribute === "") return setIsShowModalAttribute(false);
    // request.request("/api/attribute?page=78&api=find_depot&queryInput=%7B%22id%22%3A%5B%5D%7D&select=name",{
    //   name:nameAttribute,
    //   categoryId:dataSchema.categoryId,
    //   displayType:"text"
    // },{},"GET")
    // .then(res=>{
    //   if(true){
    //     helper.alert("Thêm thuộc tính thành công")
    //     console.log("@@pageId",pageId)
    //     // fetchAttributes(dataSchema.categoryId)
    //   }
    // })
    let pageInfo = await helper.getPage(pageId);
    let rs = await helper.callPageApi(pageInfo, "createAttributes", {
      name: nameAttribute,
      categoryId: dataSchema.categoryId,
      displayType: "text"
    })
    let rs2 = await helper.callPageApi(pageInfo, 'findAttributes', { categoryId: dataSchema.categoryId });
    setListSelectAttrsState(rs2.data)
    if (rs.id) {
      helper.alert("Thêm thuộc tính thành công !")
    }

  };

  const handleDataProduct = (cpDataFormAttr) => {
    let details = [];
    let cpDataProduct = [];
    let attributeValues = [];
    const a = Object.values(cpDataFormAttr);
    // console.log('onFieldsChange -> a', a);
    if (a.length === MAX_ATTRIBUTE) {
      let idx = 0, idxx = 0;
      for (let x of a[0].attrValues) {
        let idxy = 0;
        for (let y of a[1].attrValues) {
          details.push({
            isNew: true,
            id: `${idxx}-${idxy}`,
            key: idx,
            attrIds: [
              { id: a[0].attr.attrId, name: a[0].attr.name },
              { id: a[1].attr.attrId, name: a[1].attr.name }
            ],
            [a[0].attr.name]: x,
            [a[1].attr.name]: y,
          });
          idx += 1;
          idxy += 1;
        }
        idxx += 1;
      }
    } else if (a.length === MAX_ATTRIBUTE - 1) {
      let idx = 0;
      for (let x of a[0].attrValues) {
        details.push({
          isNew: true,
          id: `0-${idx}`,
          key: idx,
          attrIds: [
            { id: a[0].attr.attrId, name: a[0].attr.name }
          ],
          [a[0].attr.name]: x,
        });
        idx += 1;
      }
    }

    let frmIdx = -1;
    const tmpAttrs = Object.values(cpDataFormAttr).reduce((acc, cur) => {
      frmIdx += 1;
      return [
        ...acc,
        {
          isNew: cur.isNew || false,
          name: cur.attr.label || cur.attr.name,
          attrId: cur.attr.attrId,
          formId: Object.keys(cpDataFormAttr)[frmIdx] && Number(Object.keys(cpDataFormAttr)[frmIdx]),
          attributeValues: cur.attrValues.map(i => ({ name: i, attributeId: cur.attr.attrId }))
        },
      ];
    }, []);
    // console.log("onFieldsChange-> handleDataProduct -> details", details);
    // console.log("onFieldsChange -> handleDataProduct -> tmpAttrs", tmpAttrs);
    setPAttrValues(details);
    setDataFormAttr(cpDataFormAttr);
    if (!_.isEqual(attrs, tmpAttrs)) {
      setAttrs(tmpAttrs);
      setPAttrs(tmpAttrs);
    }
  };

  const onFieldsChange = (formId, changedFields, allFields) => {
    /* console.log('onFieldsChange -> allFields', allFields);
    console.log('onFieldsChange -> changedFields', changedFields); */
    if (
      changedFields.length > 0
      && changedFields[0].value
      && changedFields[0].value !== ""
    ) {
      const attrField = allFields.filter((f) => f.name[0] === `attr`)[0];
      let attr = attrField.value;
      let findAttr = pAttrs.find(i => i.attrId && i.attrId.toString() === attr.key.toString());
      if (!findAttr) {
        findAttr = {
          attrId: attr.key,
          name: attr.label
        };
      }
      const attrValueFields = allFields.filter(
        (f) => f.name.length === 1 && f.name[0] === "attrValues"
      )[0];

      let cpDataFormAttr = clone(dataFormAttr);
      cpDataFormAttr = _.assign(cpDataFormAttr, {
        [formId]: {
          attr: {
            ...attr,
            ...findAttr
          },
          attrValues: concat(
            _.get(dataFormAttr, "formId.attrValues") || [],
            attrValueFields.value
          ),
        },
      });
      // console.log('onFieldsChange -> cpDataFormAttr', cpDataFormAttr);
      handleDataProduct(cpDataFormAttr);
    }
  };

  const checkedValueAll = (value, formId) => {
    const allValues = _.cloneDeep(dataFormAttr);
    if (!value || value === '') {
      setError(true);
      return false;
    }
    let isnotExisted = true;
    const curValues = allValues[formId];
    const keys = Object.keys(allValues).filter(i => i.toString() !== formId.toString());
    let arrValues = [];
    keys.forEach(key => {
      arrValues = _.concat(arrValues, allValues[key]);
    });

    for (let i = 0; i < arrValues.length; i++) {
      let objVal = arrValues[i];
      if (objVal.attr.attrId && value.key && objVal.attr.attrId.toString() === value.key.toString()) {
        isnotExisted = false;
        break;
      }
    };

    setError(!isnotExisted);
    if (curValues && curValues.attrValues) {
      if (curValues.attrValues) {
        for (let aval of curValues.attrValues) {
          if (aval === '' || aval === null) {
            setError(true);
          }
        }
      }
    }
    return isnotExisted;
  };

  return (
    <>
      <Row>
        <Col span={16} offset={10}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setIsShowModalAttribute(true)}
          >Thêm thuộc tính</Button>
        </Col>
      </Row>
      <Divider></Divider>
      <List

        rowKey="id"
        grid={{ gutter: 12, xxl: 3, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        dataSource={pAttrs}
        renderItem={(item, index) => {
          return (
            <List.Item key={item.id}>
              <Row gutter={8}>
                <Col span={16} offset={4}>
                  <Card
                    title={`Nhóm phân loại ${index + 1}`}
                    bordered={true}
                    extra={
                      (itemId === null || item.isNew) && (
                        <CloseOutlined
                          onClick={() => {
                            let tmpPattrs = _.cloneDeep(pAttrs);
                            tmpPattrs = tmpPattrs.filter(
                              (i) => i.formId !== item.formId
                            );
                            let cpDataFormAttr = clone(dataFormAttr);
                            cpDataFormAttr = _.omit(cpDataFormAttr, [item.formId]);
                            // console.log('cpDataFormAttr', cpDataFormAttr);
                            // console.log('dataFormAttr', dataFormAttr);
                            // const formAnotherId = item.formId === 1 ? 2 : 1;
                            let dChanges = [];
                            if (item.formId === 1) {
                              for (let x = 1; x < (dataFormAttr[1].attrValues || []).length; x++) {
                                for (let y = 0; y < (dataFormAttr[2].attrValues || []).length; y++) {
                                  dChanges.push({
                                    formId: item.formId,
                                    attrValIndex: `${x}-${y}`,
                                    type: 'DEL'
                                  });
                                }
                              }
                            } else if (item.formId === 2) {
                              if (dataFormAttr[2].attrValues.length > 1) {
                                for (let y = 0; y < (dataFormAttr[2].attrValues || []).length; y++) {
                                  dChanges.push({
                                    formId: item.formId,
                                    attrValIndex: `0-${y}`,
                                    type: 'DEL'
                                  });
                                }
                              }
                            }
                            console.log("@@dChange", dChanges)
                            setDataChange(dChanges);
                            handleDataProduct(cpDataFormAttr);
                          }}
                        />
                      )
                    }
                    style={{ backgroundColor: "#fafafa" }}
                  >

                    <ProductAttributeValue
                      formId={item.formId}
                      onFieldsChange={onFieldsChange}
                      pAttrValue={item}
                      // allValues={dataFormAttr}
                      checkedValueAll={checkedValueAll}
                      setError={setError}
                      addProduct={(formId, attrValIndex, attrVal) => {
                        console.log('addProduct formId %o \n dataFormAttr %o', formId, dataFormAttr);
                        let dChanges = [];
                        if (Object.keys(dataFormAttr).length === MAX_ATTRIBUTE) {
                          const formAnotherId = formId === 1 ? 2 : 1;
                          for (let i = 0; i < dataFormAttr[formAnotherId].attrValues.length; i++) {
                            dChanges.push({
                              formId,
                              attrValIndex: formAnotherId === 1 ? `${i}-${attrValIndex}` : `${attrValIndex}-${i}`,
                              attrVal,
                              type: 'ADD'
                            });
                          }
                        } else if (Object.keys(dataFormAttr).length === MAX_ATTRIBUTE - 1) {
                          dChanges.push({
                            formId,
                            attrValIndex: `${0}-${attrValIndex}`,
                            attrVal,
                            type: 'ADD'
                          });
                        }

                        // console.log('dChanges', dChanges);
                        setDataChange(dChanges);
                      }}
                      removeProduct={(formId, attrValIndex) => {
                        let dChanges = [];
                        if (Object.keys(dataFormAttr).length === MAX_ATTRIBUTE) {
                          const formAnotherId = formId === 1 ? 2 : 1;
                          for (let i = 0; i < dataFormAttr[formAnotherId].attrValues.length; i++) {
                            dChanges.push({
                              formId,
                              attrValIndex: formAnotherId === 1 ? `${i}-${attrValIndex}` : `${attrValIndex}-${i}`,
                              attrVal: null,
                              type: 'DEL'
                            });
                          }
                        } else if (Object.keys(dataFormAttr).length === MAX_ATTRIBUTE - 1) {
                          dChanges.push({
                            formId,
                            attrValIndex: `${0}-${attrValIndex}`,
                            attrVal: null,
                            type: 'DEL'
                          });
                        }
                        setDataChange(dChanges);
                      }}
                      listSelectAttrs={listSelectAttrsState}
                    />
                  </Card>
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />
      {pAttrs.length < MAX_ATTRIBUTE && (
        <Button
          type="dashed"
          onClick={() => {
            const formId = pAttrs.length + 1;
            let tmpPattrs = clone(pAttrs);
            tmpPattrs.push({ atrrId: '', id: '', label: '', name: '', formId, isNew: true });

            let cpDataFormAttr = clone(dataFormAttr);
            cpDataFormAttr = _.assign(cpDataFormAttr, {
              [formId]: {
                attr: '',
                attrValues: [''],
                isNew: true
              },
            });
            console.log('1.add form attr => dataFormAttr', dataFormAttr);
            console.log('2.add form attr => cpDataFormAttr', cpDataFormAttr);
            const formAnotherId = 1;
            // let dChanges = [];
            // for (let i = 0; i < cpDataFormAttr[formAnotherId].attrValues.length; i++) {
            //   dChanges.push({
            //     formId,
            //     attrValIndex: `${i}-${0}`,
            //     attrVal: '',
            //     type: 'ADD'
            //   });
            // }
            // setDataChange(dChanges);
            setPAttrs(tmpPattrs);
            handleDataProduct(cpDataFormAttr);
            setError(true);
          }}
          style={{ width: "100%" }}
        >
          <PlusOutlined /> Thêm nhóm phân loại {pAttrs.length + 1}
        </Button>
      )}
      <Product
        pAttrs={attrs}
        pAttrValues={pAttrValues}
        onChange={onChange}
        dataChange={dataChange}
        resetDataChange={setDataChange}
        // dataSchema={dataSchema}
      />
      <Modal
        title="Thêm tên thuộc tính"
        visible={isShowModalAttribute}
        onOk={addNameAttribute}
        onCancel={() => setIsShowModalAttribute(false)}
        cancelText="Đóng"
        okText="Thêm"
      >
        <Input
          placeholder="Tên thuộc tính"
          onChange={(e) => setNameAttribute(e.target.value)}
          value={nameAttribute}
        />
      </Modal>
    </>
  );
};

export default ProductAttribute;
