import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import helper from "../../services/helper";
import FormSchema from "../../schema/index";

const schemaDetail = [
  {
    name: "Trạng thái",
    field: "isActive",
    type: "boolean",
    widget: "Checkbox",
    default: true,
  },
  {
    name: "Tên",
    field: "name",
    type: "string",
    widget: "Text",
    required: true,
  },
  {
    name: "Giá gốc",
    field: "originPrice",
    "type": "number",
    "widget": "NumberMask",
    required: true,
  },
  {
    name: "Giá bán",
    field: "price",
    "type": "number",
    "widget": "NumberMask",
    required: true,
  },
  {
    "name": "Ảnh",
    "field": "images",
    "type": "string",
    "widget": "ArrayImage",
    "required": false,
    "imageWidth": "200",
    "imageHeight": "200"
  },
  {
    "name": "Cân nặng sản phẩm",
    "field": "weight",
    "type": "number",
    "widget": "Text",
    "required": true
  }
];

const ProductDetail = ({ modalVisibile, toggle, onChange, product }) => {
  const formRef = React.createRef();
  const [modal, setModal] = useState(modalVisibile);
  const [dataDetail, setDataDetail] = useState(product);

  // if (dataDetail && dataDetail.isNew && !dataDetail.isEmbeedDataProducttype) {
  //   const dataProduct = {
  //     name: dataSchema.name,
  //     originPrice: dataSchema.originPrice,
  //     payment: dataSchema.payment,
  //     paymentCash: dataSchema.paymentCash,
  //     paymentPoint: dataSchema.paymentPoint,
  //     images: dataSchema.images,
  //     weight: dataSchema.weight,
  //     description: dataSchema.description
  //   }
  //   dataDetail.product = Object.assign(dataDetail.product, dataProduct)
  //   dataDetail.isEmbeedDataProducttype = true
  // }
  useEffect(() => {
    setDataDetail(product);
  }, [product]);

  useEffect(() => {
    setModal(modalVisibile);
  }, [modalVisibile]);

  const onSubmit = () => {
    confirm();
  };

  const confirm = () => {
    if (formRef) {
      let error = formRef.current.checkError();
      if (error >= 0)
        return helper.alert(
          `Dữ liệu chưa đúng, kiểm tra lại thông tin ${this.state.schemaDetail[error].name}`
        );
    }

    console.log("confirm -> dataDetail ", dataDetail);
    if (onChange) {
      onChange(dataDetail);
    }
    toggle();
  };
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      fade={false}
      size={"lg"}
    >
      <ModalHeader>Thêm chi tiết</ModalHeader>
      <ModalBody>
        <FormSchema
          ref={formRef}
          schema={schemaDetail}
          data={dataDetail && dataDetail.product || {}}
          onChange={(detail) => {
            setDataDetail({
              ...dataDetail,
              product: detail
            });
          }}
          onSubmit={onSubmit}
        >
          <Button
            onClick={() => {
              confirm();
            }}
          >
            Thực hiện
          </Button>
        </FormSchema>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ProductDetail;
